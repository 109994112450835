/**
 * Calls the provided {@link callbackFn} when the DOM is ready.
 *
 * This means that the callback will either be postponed until the
 * `DOMContentLoaded` event is dispatched or called immediately, if the DOM has
 * already been loaded.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/DOMContentLoaded_event
 */
export const onDomReady = (callbackFn: () => void) => {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', callbackFn, { once: true });
  } else {
    callbackFn();
  }
};
