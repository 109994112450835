/**
 * Implements the hide functionality of the
 * .social-submission__post-list__caption__expand-button button for a single
 * caption element.
 */
const initializeHandlingForCaption = (captionElement: HTMLElement): void => {
  for (const textElement of getTextElements(captionElement)) {
    textElementResizeObserver.observe(textElement);
  }
};

const handleCaptionTextResizes: ResizeObserverCallback = (resizes) => {
  for (const resize of resizes) {
    if (!(resize.target instanceof HTMLElement)) {
      continue;
    }
    const textElement = resize.target;
    const captionElement = textElement.closest<HTMLElement>(CAPTION_SELECTOR);
    if (captionElement === null) {
      continue;
    }
    if (isTextTooLarge(textElement)) {
      for (const expandButton of getExpandButtons(captionElement)) {
        expandButton.addEventListener('click', handleClickOnExpandButton);
        expandButton.addEventListener('mousedown', handleClickOnExpandButton);
      }
    } else {
      expandCaption(captionElement);
    }
  }
};

const textElementResizeObserver = new ResizeObserver(handleCaptionTextResizes);

const isTextTooLarge = (textElement: HTMLElement): boolean => {
  const computedTextStyles = getComputedStyle(textElement);
  const maxBlockSizeStr = computedTextStyles.maxBlockSize;
  const maxBlockSizeStrUnitStartIndex = maxBlockSizeStr.indexOf('px');
  let maxBlockSize: number;
  if (maxBlockSizeStrUnitStartIndex !== -1) {
    maxBlockSize = parseFloat(
      maxBlockSizeStr.substring(0, maxBlockSizeStrUnitStartIndex),
    );
  } else {
    // Firefox lacks maxBlockSize so we need to calculate it.
    const lineHeightStr = computedTextStyles.lineHeight;
    const lineHeightPxIndex = lineHeightStr.indexOf('px');
    const lineHeight = parseFloat(
      lineHeightStr.substring(0, lineHeightPxIndex),
    );
    maxBlockSize = lineHeight * LINES_SHOWN_IN_COLLAPSED_CAPTION;
  }
  return textElement.scrollHeight * MAGIC_PADDING_MULTIPLIER > maxBlockSize;
};

/**
 * The number of lines that are shown in the collapsed caption.
 * Must be capt in sync with css.
 */
const LINES_SHOWN_IN_COLLAPSED_CAPTION = 9;
const MAGIC_PADDING_MULTIPLIER = 4 / 5;

function handleClickOnExpandButton(this: HTMLButtonElement): void {
  const captionElement = this.closest<HTMLElement>(CAPTION_SELECTOR);
  if (captionElement === null) {
    return;
  }
  expandCaption(captionElement);
}

const getExpandButtons = (captionElement: HTMLElement) =>
  captionElement.querySelectorAll<HTMLButtonElement>(
    'button.social-submission__post-list__caption__expand-button',
  );

const getTextElements = (captionElement: HTMLElement) =>
  captionElement.querySelectorAll<HTMLElement>(TEXT_SELECTOR);

const expandCaption = (captionElement: HTMLElement) => {
  captionElement.classList.add(
    'social-submission__post-list__caption--expanded',
  );
  for (const expandButton of getTextElements(captionElement)) {
    expandButton.removeEventListener('click', handleClickOnExpandButton);
    expandButton.removeEventListener('mousedown', handleClickOnExpandButton);
  }
  for (const expandButton of getExpandButtons(captionElement)) {
    expandButton.removeEventListener('click', handleClickOnExpandButton);
    expandButton.removeEventListener('mousedown', handleClickOnExpandButton);
  }
};

const CAPTION_CLASS_NAME = 'social-submission__post-list__caption';
const CAPTION_SELECTOR = `.${CAPTION_CLASS_NAME}`;
const TEXT_SELECTOR = '.social-submission__post-list__caption__text';

export const initializeCaptionExpansion = (): void => {
  const mutationObserver = new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      for (const addedNode of mutation.addedNodes) {
        if (addedNode instanceof Element) {
          if (
            addedNode instanceof HTMLElement &&
            addedNode.classList.contains(CAPTION_CLASS_NAME)
          ) {
            initializeHandlingForCaption(addedNode);
          }
          addedNode
            .querySelectorAll<HTMLElement>(CAPTION_SELECTOR)
            .forEach(initializeHandlingForCaption);
        }
      }
    }
  });
  document
    .querySelectorAll<HTMLElement>(CAPTION_SELECTOR)
    .forEach(initializeHandlingForCaption);
  mutationObserver.observe(document.documentElement, {
    childList: true,
    subtree: true,
  });
};
