import { onDomReady } from './on-dom-ready';

export const initializeShowMore = () => {
  onDomReady(realInitializeShowMore);
};

const realInitializeShowMore = () => {
  for (const postListContainer of document.querySelectorAll<HTMLElement>(
    CONTAINER_SELECTOR,
  )) {
    const moreLink = getMoreLink(postListContainer);
    if (moreLink === null) {
      continue;
    }
    if (getCardList(postListContainer) === null) {
      continue;
    }
    registerShowMoreButtonHandlers(postListContainer);
  }
};

const handleShowMoreButtonClick = (e: MouseEvent) => {
  if (!(e.target instanceof Element)) {
    return;
  }
  const postListContainer = e.target.closest(CONTAINER_SELECTOR)!;
  unregisterShowMoreButtonHandlers(postListContainer);
  loadMore(postListContainer);
};

const handleShowMoreMouseDown = (e: MouseEvent) => {
  if (e.button !== 0) {
    return;
  }
  handleShowMoreButtonClick(e);
};

const loadMore = async (postListContainer: Element) => {
  try {
    const moreLink = getMoreLink(postListContainer);
    if (moreLink === null) {
      return;
    }
    let nextMoreUrl: string | null = moreLink.href;
    try {
      const response = await fetch(moreLink.href);
      const htmlPatch = await response.text();
      const list = getCardList(postListContainer)!;
      list.innerHTML = list.innerHTML.concat(htmlPatch);
      nextMoreUrl = response.headers.get('Next-Page');
    } finally {
      if (nextMoreUrl === null) {
        for (const showMoreButton of getShowMoreButtons(postListContainer)) {
          showMoreButton.remove();
        }
      } else {
        moreLink.href = nextMoreUrl;
        registerShowMoreButtonHandlers(postListContainer);
      }
    }
  } catch (e: unknown) {
    console.error('Encountered unhandled error during load more', e);
  }
};

const CONTAINER_SELECTOR = '.social-submission__post-list__container';

const getMoreLink = (postListContainer: Element) =>
  postListContainer.querySelector<HTMLAnchorElement>(
    'a.social-submission__post-list__show-more-link',
  );
const getCardList = (postListContainer: Element) =>
  postListContainer.querySelector<HTMLElement>('.social-submission__post-list');

const getShowMoreButtons = (postListContainer: Element) =>
  postListContainer.querySelectorAll<HTMLButtonElement>(
    'button.social-submission__post-list__show-more-button',
  );

const EVENT_LISTENER_OPTIONS = { once: true } satisfies AddEventListenerOptions;

const registerShowMoreButtonHandlers = (postListContainer: Element) => {
  for (const showMoreButton of getShowMoreButtons(postListContainer)) {
    showMoreButton.addEventListener(
      'mousedown',
      handleShowMoreMouseDown,
      EVENT_LISTENER_OPTIONS,
    );
    showMoreButton.addEventListener(
      'click',
      handleShowMoreButtonClick,
      EVENT_LISTENER_OPTIONS,
    );
    showMoreButton.disabled = false;
  }
};

const unregisterShowMoreButtonHandlers = (postListContainer: Element) => {
  for (const showMoreButton of getShowMoreButtons(postListContainer)) {
    showMoreButton.removeEventListener('mousedown', handleShowMoreMouseDown);
    showMoreButton.removeEventListener('click', handleShowMoreButtonClick);
    showMoreButton.disabled = true;
  }
};
